<script>

</script>
<template>
    <b-container>
      <b-row>
        <b-col>
          <b-strong>Privacy Policy</b-strong>
          <p>
            This privacy policy applies to the IMS JDN app (hereby referred to as "Application") for mobile devices that was
            created by PT JAVA DIGITAL NUSANTARA (hereby referred to as "Service Provider") as a Free service. This service
            is intended for use "AS IS".
          </p>
          <br />
  
          <b-strong>Information Collection and Use</b-strong>
          <p>The Application collects information when you download and use it. This information may include:</p>
          <b-list-group>
            <b-list-group-item>Your device's Internet Protocol address (e.g. IP address)</b-list-group-item>
            <b-list-group-item>The pages of the Application that you visit, the time and date of your visit, the time spent on those pages</b-list-group-item>
            <b-list-group-item>The time spent on the Application</b-list-group-item>
            <b-list-group-item>The operating system you use on your mobile device</b-list-group-item>
          </b-list-group>
          <br />
  
          <p style="display: none;">The Application does not gather precise information about the location of your mobile device.</p>
          <p>The Application collects your device's location, which helps the Service Provider determine your approximate geographical location and make use of in the below ways:</p>
          <b-list-group>
            <b-list-group-item>
              Geolocation Services: The Service Provider utilizes location data to provide features such as personalized content, relevant recommendations, and location-based services.
            </b-list-group-item>
            <b-list-group-item>
              Analytics and Improvements: Aggregated and anonymized location data helps the Service Provider to analyze user behavior, identify trends, and improve the overall performance and functionality of the Application.
            </b-list-group-item>
            <b-list-group-item>
              Third-Party Services: Periodically, the Service Provider may transmit anonymized location data to external services to enhance the Application and optimize their offerings.
            </b-list-group-item>
          </b-list-group>
          <br />
  
          <p>The Service Provider may use the information you provided to contact you from time to time to provide you with important information, required notices, and marketing promotions.</p>
          <br />
  
          <b-strong>Third Party Access</b-strong>
          <p>Only aggregated, anonymized data is periodically transmitted to external services to aid the Service Provider in improving the Application and their service. The Service Provider may share your information with third parties as described in this privacy statement.</p>
          <br />
  
          <p>
            Please note that the Application utilizes third-party services that have their own Privacy Policy about handling data. Below are the links to the Privacy Policy of the third-party service providers used by the Application:
          </p>
          <b-list-group>
            <b-list-group-item>
              <b-link href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</b-link>
            </b-list-group-item>
          </b-list-group>
          <br />
  
          <p>The Service Provider may disclose User Provided and Automatically Collected Information:</p>
          <b-list-group>
            <b-list-group-item>as required by law, such as to comply with a subpoena, or similar legal process;</b-list-group-item>
            <b-list-group-item>when they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</b-list-group-item>
            <b-list-group-item>with their trusted service providers who work on their behalf, and have agreed to adhere to this privacy statement.</b-list-group-item>
          </b-list-group>
          <br />
  
          <b-strong>Opt-Out Rights</b-strong>
          <p>You can stop all collection of information by the Application easily by uninstalling it. Use the standard uninstall processes available as part of your mobile device or via the mobile application marketplace or network.</p>
          <br />
  
          <b-strong>Data Retention Policy</b-strong>
          <p>The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you'd like them to delete User Provided Data, please contact them at batu@nusantara.net.id and they will respond in a reasonable time.</p>
          <br />
  
          <b-strong>Children</b-strong>
          <p>The Service Provider does not knowingly collect personally identifiable information from children under 13 years of age. If you are aware that your child has provided personal information, contact them at batu@nusantara.net.id.</p>
          <br />
  
          <b-strong>Security</b-strong>
          <p>The Service Provider is concerned about safeguarding the confidentiality of your information. They provide physical, electronic, and procedural safeguards to protect the information.</p>
          <br />
  
          <b-strong>Changes</b-strong>
          <p>This Privacy Policy may be updated from time to time. You are advised to consult this Privacy Policy regularly for any changes.</p>
          <br />
  
          <p>This privacy policy is effective as of 2024-09-01.</p>
          <br />
  
          <b-strong>Your Consent</b-strong>
          <p>By using the Application, you consent to the processing of your information as set forth in this Privacy Policy.</p>
          <br />
  
          <b-strong>Contact Us</b-strong>
          <p>If you have any questions about privacy while using the Application, contact the Service Provider at batu@nusantara.net.id.</p>
          <hr />
        </b-col>
      </b-row>
    </b-container>
  </template>
  
  <script>
  export default {
    name: "PrivacyPolicy",
  };
  </script>
  